%row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

%column {
  display: flex;
  flex-flow: column nowrap;
}

%flex-wrap {
  flex-wrap: wrap;
}

////////////////////////////////////////////////////////////////////////////////
// Primary Flex Structures

.row {
  @extend %row; }

.row-wrap {
  @extend %row;
  @extend %flex-wrap;
}

.column {
  @extend %column; }

.column-wrap {
  @extend %column;
  @extend %flex-wrap; }


////////////////////////////////////////////////////////////////////////////////
// Allignment Modifiers

.row-top {
  @extend %row;
  align-items: flex-start; }

.row-bottom {
  @extend %row;
  align-items: flex-end; }

.row-baseline {
  @extend %row;
  align-items: baseline; }

.row-right {
  @extend %row;
  justify-content: flex-end; }

.row-center {
  @extend %row;
  justify-content: center; }

.row-verticalCenter {
  @extend %row;
  align-items: center; }


//////////////////////////////////////////////////////////////////////////////////
// Gap Setting

// @TODO size variables w/ ionic
.gap-sm {
  gap: 3px;
}

.gap {
  gap: 5px;
}

.gap-lg {
  gap: 10px;
}

.gap-xl {
  gap: 15px;
}

.gap-xxl {
  gap: 20px;
}

// Display
.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.align-items-top {
  align-items: top;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center{
  justify-content: center;
}

.justify-content-sb {
  justify-content: space-between;
}

.flex-item {
  display: flex;
  flex-flow: column;
}

// Direction
.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.flex-direction-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.align-items-flex-end {
  align-items: flex-end;
}

.flex-flow-row-reverse {
  flex-flow: row-reverse;
}

  // ------------TIR--------------//
.op-80 {
  opacity: 80%;
}

.op-70 {
  opacity: 70%;
}

.vertical-scroll {
  overflow-y: auto;
  height: 85vh;
  mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 5px, #007fc5 5px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 6px, #007fc5 5px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;

  &:hover {
    --webkit-mask-position: left top;
  }
}

.fs-20 {
  font-size: 20px;
}

.text-decoration-none {
  text-decoration: none;
}

// position
.pr {
  position: relative;
}

.pa {
  position: absolute;
}

//pointer
.pointer {
  cursor: pointer;
}
 //ion button effect
 .no-ripple {
  --ripple-color: transparent;
}

.text-align-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}