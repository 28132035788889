
@mixin button($fw, $fs, $br) {
  font-family: var( --q-font-family);
  font-weight: ($fw);
  font-size: ($fs);
  font-style: normal;
  justify-content: center;
  align-items: center;
  border-radius: ($br);
  height: 44px;
}
.filled-clear-btn{
  
  @include button(var(--q-font-weight-regular), var(--q-large-20-font-size), 5px);
  background-color: var(--ion-color-white)!important;
  color: var(--ion-color-highlight);
  height: 44px;
  ion-icon{
    font-size: 24px;
    margin-left: 8px;
  }
  --box-shadow: none !important;
}
.filled-icon-btn{
  
  @include button(var(--q-font-weight-bold), var(--q-large-20-font-size), 5px);
  background-color: var(--ion-button-bg-color)!important;
  color: var(--ion-color-white);
  height: 44px;
  ion-icon{
    font-size: 24px;
    margin-left: 8px;
  }
  --box-shadow: none !important;
}

.filled-outline-btn{
  @include button(var(--q-font-weight-regular), var(--q-large-20-font-size), 5px);
  height: 44px;
  --border-radius:5px!important;
  ion-icon{
    font-size: 24px;
  }
}
.round-icon-button {
  background-color: transparent;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  &:hover{
    border: 1px solid var(--ion-color-highlight);
  }
}



